import { ClockConcernsPropType } from 'components/report/SegmentComponents/ClockConcerns/ClockConcerns';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { FeatureFlags, useFeatureFlag } from 'features/feature-flags';
import { t } from 'i18n';
import { SegmentType } from '../../../../../generated/graphql';
import { H4 } from '../../../../shared/designSystem/TextComponents';
import { FormattedSegmentResults } from '../../../cognitiveEvalParseData/cognitiveEvalParseData';
import { DctClockRecording } from '../../../SegmentComponents/DctClockRecording/DctClockRecording';
import { RecallRecording } from '../../../SegmentComponents/RecallRecording/RecallRecording';
import { PrintPageHeader } from '../PrintPageHeader';

type MiniModalRecordingsProps = {
	miniModalRecordingSegments: FormattedSegmentResults[];
	copyClockLoaded?: () => void;
	commandClockLoaded?: () => void;
	clockConcernsEnabled?: boolean;
};

type ProcessedShortPhraseKeys = {
	copyShortPhraseData: string[];
	commandShortPhraseData: string[];
};

function getShortPhraseTranslation(key: string) {
	const regex = /^web\.dcrReport\.recordings\.clock\.shortPhrases\.\w+$/;

	if (regex.test(key)) {
		return t(key);
	}

	return t(`web.dcrReport.recordings.clock.shortPhrases.${key}`);
}

export const processShortPhraseMetricItems = (
	data: FormattedSegmentResults,
	isShortPhrasesV2?: boolean
): ProcessedShortPhraseKeys => {
	const copyShortPhraseData: string[] = [];
	const commandShortPhraseData: string[] = [];

	const metrics = data.metricItems;
	const expectedPhraseCount = Number(
		metrics[`expected_short_phrases`]?.value
	);

	if (isNaN(expectedPhraseCount)) {
		return { copyShortPhraseData, commandShortPhraseData };
	}

	for (let i = 1; i <= expectedPhraseCount; i++) {
		const clockType = metrics[`short_phrase_${i}_clock`]?.value as string;
		let shortPhraseKey = metrics[`short_phrase_${i}_key`]?.value as string;

		if (!shortPhraseKey) {
			const nestedKeys = [];

			for (let j = 1; j <= 4; j++) {
				const nestedKey = metrics[`short_phrase_${i}_key_${j}`]
					?.value as string;

				if (nestedKey) {
					if (!isShortPhrasesV2) {
						nestedKeys.push(
							t(
								'web.dcrReport.recordings.clock.shortPhrases.messyClock'
							)
						);
						// we always want to show the Messy clock string whenever the short phrases flag is disabled
						// so we can stop the process here
						break;
					}
					nestedKeys.push(getShortPhraseTranslation(nestedKey));
				}
			}

			if (nestedKeys.length > 0) {
				shortPhraseKey = nestedKeys.join(', ');
			}
		} else {
			shortPhraseKey = t(getShortPhraseTranslation(shortPhraseKey));
		}

		if (clockType && shortPhraseKey) {
			if (clockType === 'COP') {
				copyShortPhraseData.push(shortPhraseKey);
			} else if (clockType === 'COM') {
				commandShortPhraseData.push(shortPhraseKey);
			}
		}
	}

	return { copyShortPhraseData, commandShortPhraseData };
};

export function parsePhrases(
	data: FormattedSegmentResults,
	isShortPhrasesV2?: boolean
) {
	const defaultClock = {
		concerns: [],
		disclaimer: t`web.dcrReport.recordings.clock.shortPhrases.disclaimer`,
		title: t`web.dcrReport.recordings.clock.shortPhrases.mainConcerns`,
		betaTitle: t``,
	};
	const { copyShortPhraseData, commandShortPhraseData } =
		processShortPhraseMetricItems(data, isShortPhrasesV2);

	const copyClockShortPhrases: ClockConcernsPropType | undefined = {
		...defaultClock,
		concerns: copyShortPhraseData,
		sectionName: 'copy',
	};
	const commandClockShortPhrases: ClockConcernsPropType | undefined = {
		...defaultClock,
		concerns: commandShortPhraseData,
		sectionName: 'command',
	};

	return {
		copyClockShortPhrases,
		commandClockShortPhrases,
	};
}

export const MiniModalRecordings = ({
	miniModalRecordingSegments,
	copyClockLoaded,
	commandClockLoaded,
	clockConcernsEnabled,
}: MiniModalRecordingsProps): JSX.Element | null => {
	const { t } = useTranslation();
	const isShortPhrasesV2 = useFeatureFlag(FeatureFlags.ShortPhrasesV2);

	const recallDelayedSegment = miniModalRecordingSegments.find(
		(segment) => segment.segmentType === SegmentType.RecallDelayed
	);
	const recallImmediateSegment = miniModalRecordingSegments.find(
		(segment) => segment.segmentType === SegmentType.RecallImmediate
	);

	const dctClockSegment = miniModalRecordingSegments.find(
		(segment) => segment.segmentType === SegmentType.Clock
	);

	if (!dctClockSegment || !recallImmediateSegment || !recallDelayedSegment)
		return null;

	let copyClockShortPhrases: ClockConcernsPropType | undefined = undefined;
	let commandClockShortPhrases: ClockConcernsPropType | undefined = undefined;

	if (clockConcernsEnabled) {
		const result = parsePhrases(dctClockSegment, isShortPhrasesV2);
		copyClockShortPhrases = result.copyClockShortPhrases;
		commandClockShortPhrases = result.commandClockShortPhrases;
	}

	return (
		<StyledContainer>
			<StyledH4>
				<Trans i18nKey='web.dcrReport.recordings.title' />
			</StyledH4>
			<StyledPrintPageHeader title={t`web.dcrReport.recordings.title`} />
			<StyledWrapper>
				<StyledColumn>
					<StyledRecall>
						{/* TODO: Find a better solution for this  */}
						{/* hidden audio player temp solution for WEB-2559 */}
						<div
							style={{
								visibility: 'hidden',
								height: 0,
							}}
						>
							<audio controls></audio>
						</div>
						<RecallRecording
							subTitle={t`web.dcrReport.recordings.recall.immediate.subtitle`}
							title={t`web.dcrReport.recordings.recall.immediate.title`}
							segment={recallImmediateSegment}
							tooltipText={t`web.dcrReport.recordings.recall.immediate.tooltipText`}
						/>
					</StyledRecall>
					<StyledClock>
						<DctClockRecording
							title={t`web.dcrReport.recordings.clock.copy.title`}
							clockType={'COPY'}
							tooltipText={t`web.dcrReport.recordings.clock.copy.tooltipText`}
							segmentId={dctClockSegment.id}
							contentLoaded={copyClockLoaded}
							concern={copyClockShortPhrases}
						/>
					</StyledClock>
				</StyledColumn>
				<StyledColumn>
					<StyledClock>
						<DctClockRecording
							title={t`web.dcrReport.recordings.clock.command.title`}
							clockType={'COMMAND'}
							tooltipText={t`web.dcrReport.recordings.clock.command.tooltipText`}
							segmentId={dctClockSegment.id}
							contentLoaded={commandClockLoaded}
							concern={commandClockShortPhrases}
						/>
					</StyledClock>
					<StyledRecall>
						<RecallRecording
							subTitle={t`web.dcrReport.recordings.recall.delayed.subtitle`}
							title={t`web.dcrReport.recordings.recall.delayed.title`}
							segment={recallDelayedSegment}
							tooltipText={t`web.dcrReport.recordings.recall.delayed.tooltipText`}
						/>
					</StyledRecall>
				</StyledColumn>
			</StyledWrapper>
		</StyledContainer>
	);
};

const StyledContainer = styled.div(
	({ theme: { spacing } }) => css`
		width: 100%;
		margin-bottom: ${spacing.xl};

		@media print {
			margin-top: ${spacing.xl};
			break-before: page;
		}
	`
);

const StyledWrapper = styled.div(
	({ theme: { spacing } }) => css`
		height: auto;
		display: flex;
		gap: ${spacing.xl};
	`
);

const StyledColumn = styled.div(
	({ theme: { spacing } }) => css`
		display: flex;
		flex-direction: column;
		min-height: auto;
		gap: ${spacing.xl};
		width: 100%;

		@media print {
			min-height: 0;
		}
	`
);

const StyledClock = styled.div`
	height: auto;
`;

const StyledRecall = styled.div`
	@media print {
		display: none;
	}
`;

const StyledPrintPageHeader = styled(PrintPageHeader)`
	display: none;

	@media print {
		display: flex;
	}
`;

const StyledH4 = styled(H4)(
	({ theme: { spacing, weight, color } }) => css`
		font-weight: ${weight.medium};
		color: ${color.textSubtitle};
		margin-left: ${spacing.lg};
		margin-bottom: ${spacing.lg};

		@media print {
			display: none;
		}
	`
);
