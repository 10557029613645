import {
	DeepAssessmentResult,
	DeepSegmentResult,
	SegmentType,
} from '@lh/eng-platform-battery-service-rest-client';
import { useCallback, useEffect, useReducer } from 'react';
import {
	FormattedAssessmentResults,
	FormattedSegmentResults,
} from '../cognitiveEvalParseData/cognitiveEvalParseData';
import { Report } from '../CognitiveEvaluation.types';
import {
	createMapFromMetricsArray,
	MetricHash,
} from '../segmentUtilities/metrics';

import { useGetLhqReportArtifact } from './useGetLhqReportArtifact';
import { Questionnaire } from 'enums/questionnaire';

type BinaryArtifactData = { source: string; fileType: string };

type GetReportDataType = {
	error?: string | null;
	loading: boolean;
	metadata?: unknown;
	binaryArtifactData?: BinaryArtifactData[] | null;
};

const enum Actions {
	INIT = 'INIT',
	COMPLETED = 'COMPLETED',
	FAILED = 'FAILED',
}

type State = {
	loading: boolean;
	metadata: unknown | null;
	binaryArtifactData: BinaryArtifactData[] | null;
	error: string | null;
};

type MetaDataType = unknown;

type Action =
	| { type: Actions.INIT }
	| {
			type: Actions.COMPLETED;
			payload: {
				binaryArtifactData: BinaryArtifactData[];
				metadata: MetaDataType;
			};
	  }
	| { type: Actions.FAILED; payload: string };

const initialState: State = {
	loading: false,
	metadata: null,
	binaryArtifactData: [],
	error: null,
};

function reducer(state: State, action: Action) {
	switch (action.type) {
		case Actions.INIT:
			return { ...state, loading: true, error: null };
		case Actions.COMPLETED:
			return {
				...state,
				loading: false,
				error: null,
				binaryArtifactData: action?.payload?.binaryArtifactData,
				metadata: action?.payload?.metadata,
			};
		case Actions.FAILED:
			return { ...state, loading: false, error: action?.payload };
		default:
			return initialState;
	}
}

export const useGetLHQReportData = (
	segmentResultId: string
): GetReportDataType => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const { getReportArtifact: getJsonArtifact } = useGetLhqReportArtifact();

	const getReportArtifactsAsync = useCallback(async () => {
		dispatch({ type: Actions.INIT });
		try {
			const response = await getJsonArtifact({
				segmentResultId,
				fileName: 'answers.json',
			});

			if (!response) {
				throw new Error(
					`No artifact data found for lhq segment result with ID ${segmentResultId}`
				);
			}

			const jsonArtifact = JSON.parse(response?.assetData);

			dispatch({
				type: Actions.COMPLETED,
				payload: {
					metadata: jsonArtifact,
					binaryArtifactData: [],
				},
			});
			return;
		} catch (error: unknown) {
			console.warn(error);
			dispatch({
				type: Actions.FAILED,
				payload: (error as Error)?.message,
			});
		}
	}, [getJsonArtifact, segmentResultId]);

	useEffect(() => {
		if (!segmentResultId) return;
		getReportArtifactsAsync();
	}, [getJsonArtifact, getReportArtifactsAsync, segmentResultId]);

	return state;
};

export const getLHQMetrics = (
	lifeAndHealthAssessment: FormattedAssessmentResults | undefined
): { elevatedRisk: boolean; modifiableFactors: boolean } => {
	const segmentResults = lifeAndHealthAssessment?.segmentResults ?? [];

	let lhqSegment: FormattedSegmentResults | undefined;

	segmentResults.forEach((result) => {
		if (
			'lhqElevatedRisk' in result.metricItems ||
			'lhq32_modifiable_factors' in result.metricItems
		) {
			lhqSegment = result;
		}
	});

	const elevatedRisk =
		'lhqElevatedRisk' in (lhqSegment?.metricItems ?? {}) &&
		lhqSegment?.metricItems.lhqElevatedRisk.value === 'true';
	const modifiableFactors =
		'lhq32_modifiable_factors' in (lhqSegment?.metricItems ?? {}) &&
		lhqSegment?.metricItems.lhq32_modifiable_factors.value === 'true';
	return { elevatedRisk, modifiableFactors };
};

interface LhqAssessmentDataProps {
	batteryResult?: Report;
	segmentResult?: DeepSegmentResult;
}

interface LhqAssessmentResultsProps {
	assessmentResult?: DeepAssessmentResult;
	segmentResult?: DeepSegmentResult;
}

const formatLhqAssessmentResults = ({
	assessmentResult,
	segmentResult,
}: LhqAssessmentResultsProps): FormattedAssessmentResults | undefined => {
	let assessmentMetrics: MetricHash = {};
	let updatedSegmentResults: FormattedSegmentResults[] = [];

	if (segmentResult && !assessmentResult) {
		assessmentMetrics = segmentResult.metricItems
			? createMapFromMetricsArray(segmentResult.metricItems)
			: {};
		updatedSegmentResults = [
			{
				id: segmentResult.id,
				rawDataUrl: segmentResult.rawDataUrl,
				name: segmentResult.segment.name,
				segmentType: segmentResult.segment.segmentType,
				metricItems: segmentResult.metricItems
					? createMapFromMetricsArray(segmentResult.metricItems)
					: {},
			},
		];

		return {
			metricItems: assessmentMetrics,
			segmentResults: updatedSegmentResults,
		};
	}

	if (!segmentResult && assessmentResult) {
		assessmentMetrics = assessmentResult?.metricItems
			? createMapFromMetricsArray(assessmentResult.metricItems)
			: {};
		updatedSegmentResults = assessmentResult?.segmentResults
			? assessmentResult.segmentResults.map((segmentResult) => {
					/* eslint-disable no-mixed-spaces-and-tabs */
					return {
						id: segmentResult.id,
						rawDataUrl: segmentResult.rawDataUrl,
						name: segmentResult.segment.name,
						segmentType: segmentResult.segment.segmentType,
						metricItems: segmentResult.metricItems
							? createMapFromMetricsArray(
									segmentResult.metricItems
							  )
							: {},
					};
			  })
			: [];
		return {
			assessment: assessmentResult?.assessment,
			metricItems: assessmentMetrics,
			segmentResults: updatedSegmentResults,
		};
	}
};

export const getLhqAssessmentData = ({
	batteryResult,
	segmentResult,
}: LhqAssessmentDataProps): FormattedAssessmentResults | undefined => {
	const assessmentResult =
		batteryResult?.batteryResultById?.assessmentResults?.find((ar) => {
			return ar.segmentResults.some(
				(sr) =>
					sr.segment.segmentType === SegmentType.CustomQuestionnaire
			);
		});

	const assessmentResults = assessmentResult
		? formatLhqAssessmentResults({
				assessmentResult,
		  })
		: formatLhqAssessmentResults({
				segmentResult,
		  });

	if (assessmentResults) return assessmentResults;
};

export function getLhqSegmentId(assessmentResults?: DeepAssessmentResult[]) {
	let segmentId = '';
	if (assessmentResults) {
		assessmentResults.forEach((result) => {
			result.segmentResults.forEach((segmentResult) => {
				if (
					segmentResult.segment.metadata?.qnrId ===
					Questionnaire.LHQ15
				) {
					segmentId = segmentResult.id;
				}
				if (
					segmentResult.segment.metadata?.qnrId ===
					Questionnaire.LHQ32
				) {
					segmentId = segmentResult.id;
				}
			});
		});
	}

	return segmentId;
}
