import {
	DeepSegmentResult,
	SegmentType,
} from '@lh/eng-platform-battery-service-rest-client';
import {
	Answer,
	LHQ15_QUESTIONS as questions15,
	questionParser,
	TLHQWithSchemaResponsedata,
} from '@lh/eng-lhq-questions-common';

import { useTranslation } from 'react-i18next';
import { RefObject, useEffect, useMemo, useState } from 'react';
import { styled } from 'styled-components';

import { LoadingDots } from 'components/shared/LoadingDots';

import { Report } from '../CognitiveEvaluation.types';
import { QuestionnaireReport } from '../SegmentComponents/questionnaire/QuestionnaireReport';
import { SegmentQuestionsPair } from '../SegmentComponents/types';
import {
	getLHQMetrics,
	useGetLHQReportData,
	getLhqAssessmentData,
	getLhqSegmentId,
} from './Lhq.helpers';

type LifeAndHealthReportProps = {
	batteryResult?: Report;
	segmentResult?: DeepSegmentResult;
	offsetComponentRef?: RefObject<HTMLDivElement>;
	isLoading?: boolean;
	setIsLoading?: (isLoading: boolean) => void;
};
export function Lhq15({
	batteryResult,
	segmentResult,
	offsetComponentRef,
	isLoading,
}: Readonly<LifeAndHealthReportProps>) {
	const { t } = useTranslation();

	const [answers, setAnswers] = useState<Answer[]>();

	const segmentId = getLhqSegmentId(
		batteryResult?.batteryResultById?.assessmentResults
	);
	const { loading, metadata } = useGetLHQReportData(segmentId);

	const lhqData = useMemo(
		() =>
			getLhqAssessmentData({
				batteryResult,
				segmentResult,
			}),
		[batteryResult, segmentResult]
	);

	const { elevatedRisk } = getLHQMetrics(lhqData);

	useEffect(() => {
		if (metadata && !loading) {
			const { results } = questionParser(
				metadata as TLHQWithSchemaResponsedata
			);

			setAnswers(results);
		}
	}, [metadata, loading]);

	if (loading || isLoading)
		return (
			<StyledLoadingContainer>
				<LoadingDots />
			</StyledLoadingContainer>
		);

	if (!answers) {
		return null;
	}

	const segmentQuestions: SegmentQuestionsPair<string> = {
		segmentType: SegmentType.Lhq15,
		questions: questions15,
	};

	return (
		<QuestionnaireReport
			title={t`web.report.lifeAndHealth.title`}
			subHeader={t`web.report.lifeAndHealth.subHeader`}
			segmentQuestions={segmentQuestions}
			patientAnswers={answers}
			tooltipCopy={t`web.report.lifeAndHealth.tooltip.copy`}
			tooltipMeasures={t`web.report.lifeAndHealth.tooltip.measures`}
			elevatedRisk={elevatedRisk}
			offsetComponentRef={offsetComponentRef}
		/>
	);
}

const StyledLoadingContainer = styled.div`
	height: 40vh;
`;
