import {
	PaginatedPatients,
	PatientApiGetPatientsByOrgRequest,
} from '@lh/eng-platform-subject-service-rest-client';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { getPatientSubService } from 'api/getSubjectService';
import { QueryKey } from 'api/query';

export async function getPatientsCount(
	searchInput: PatientApiGetPatientsByOrgRequest,
	signal?: AbortSignal
) {
	if (!searchInput.organizationId) {
		throw new Error(
			"useGetPatientsCountQuery: organizationId can't be null"
		);
	}

	const service = await getPatientSubService();
	const { data } = await service.getPatientsByOrg(
		{ ...searchInput, pageSize: 0 },
		{ signal }
	);
	return data;
}

export function usePatientsCountQuery(
	searchInput: PatientApiGetPatientsByOrgRequest
) {
	const client = useQueryClient();

	const result = useQuery({
		enabled: !!searchInput.organizationId,
		meta: {
			errorMessage:
				'Error fetching total number of patients by organization',
		},
		queryKey: [QueryKey.Patients, searchInput.organizationId],
		queryFn: ({ signal }) => getPatientsCount(searchInput, signal),
		staleTime: 60 * 1000, // 1 minute,
	});

	useEffect(() => {
		if (result.data) {
			client.setQueryData<PaginatedPatients>(
				[QueryKey.Patients, searchInput.organizationId],
				result.data
			);
		}
	}, [result.data]);

	return result;
}
