import {
	DefaultApiUpdateSubjectRequest,
	Subject,
} from '@lh/eng-platform-subject-service-rest-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getSubjectService } from 'api/getSubjectService';
import { QueryKey } from 'api/query';

export async function updateSubject(
	searchInput: DefaultApiUpdateSubjectRequest
) {
	if (!searchInput.organizationId) {
		throw new Error(
			"useUpdateSubjectMutation: organizationId cant' be null"
		);
	}

	if (!searchInput.subjectId) {
		throw new Error("useUpdateSubjectMutation: subjectId cant' be null");
	}

	if (!searchInput.updateSubjectInput) {
		throw new Error(
			"useUpdateSubjecttMutation: updateSubjectInput cant' be null"
		);
	}
	const service = await getSubjectService();
	const { data } = await service.updateSubject(searchInput);
	return data;
}

export function useUpdateSubject() {
	const client = useQueryClient();

	return useMutation({
		meta: {
			errorMessage: 'Error updating subject',
		},
		mutationFn: updateSubject,
		onSuccess: async (updatedSubject) => {
			client.setQueryData<Subject>(
				[
					QueryKey.Subject,
					updatedSubject.id,
					updatedSubject.organizationId,
				],
				updatedSubject
			);
		},
	});
}
