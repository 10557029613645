import { Configuration, DefaultApi, SearchApi } from '@lh/core-backend-client';
import { config } from 'config';
import { Auth } from 'features/auth-service';

export async function getOrganizationService() {
	try {
		const jwt = await Auth.currentIdToken();
		return new DefaultApi(
			new Configuration({
				basePath: `${config.apiProtocol}://${config.apiHost}:${config.apiPort}`,
				baseOptions: {
					headers: {
						Authorization: `Bearer ${jwt.token}`,
					},
				},
			})
		);
	} catch (err) {
		throw new Error('Unauthorized: ', err as any);
	}
}

export async function getOrganizationSearchService() {
	try {
		const jwt = await Auth.currentIdToken();
		return new SearchApi(
			new Configuration({
				basePath: `${config.apiProtocol}://${config.apiHost}:${config.apiPort}`,
				baseOptions: {
					headers: {
						Authorization: `Bearer ${jwt.token}`,
					},
				},
			})
		);
	} catch (err) {
		throw new Error('Unauthorized: ', err as any);
	}
}

export { SearchApi, DefaultApi };
