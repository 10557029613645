import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { loginLandingPage } from '../../auth/login/loginLandingPage';
import { UserContext } from '../../context/UserContext';
import { usePreferences } from 'store';

import { INFO } from 'logging/linusLogger';

export const RootRoute = (): JSX.Element => {
	const { currentUser } = useContext(UserContext);

	usePreferences();

	if (!currentUser?.id) {
		return <Navigate to={`auth/login${window.location.search}`} />;
	}

	INFO('RootRoute >> window params: ', window.location.search);

	return (
		<Navigate
			to={`${loginLandingPage(currentUser)}${window.location.search}`}
		/>
	);
};
