import { OrganizationServiceErrors } from './organization/types';
export {
	Handedness,
	SexAssignedAtBirth,
	FeatureType,
	Gender,
	Status,
	AssignmentStatus,
	Persona,
} from '@lh/core-backend-client';
export type {
	Patient,
	PatientApiUpdatePatientRequest,
} from '@lh/core-backend-client';

export type ErrorData = {
	errorCode: OrganizationServiceErrors;
	message: string;
};
