import { DeepSegmentResult } from '@lh/eng-platform-battery-service-rest-client';

import { removeQuotesAndBrackets } from 'utils/stringUtils';

import {
	DelayedRecallPerformance,
	DelayedRecallTrial,
} from './DelayedRecall.types';
import { createDelayedRecallScore } from './createDelayedRecallScore';

function processPrompt(prompt: string) {
	if (!prompt) {
		return '--';
	}

	return removeQuotesAndBrackets(prompt)
		.split(',')
		.map((word) => word[0].toUpperCase() + word.substring(1))
		.join(', ');
}

export function parseDelayedRecallMetrics(segmentResult: DeepSegmentResult) {
	const performance: DelayedRecallPerformance = {
		impaired: -1,
		percentile: null,
		rcr: false,
		score: {
			adjusted: false,
			value: null,
		},
	};
	let rcr = false;
	const trial: DelayedRecallTrial = {
		prompt: '',
		url: '',
	};

	segmentResult.metricItems.forEach((metricItem) => {
		if (metricItem.key.includes('pvlt_recall_score')) {
			performance.score.value = isNaN(Number(metricItem.value))
				? null
				: createDelayedRecallScore(metricItem.value);
		}

		if (metricItem.key.includes('pvlt_expected_words')) {
			trial.prompt = processPrompt(metricItem.value);
		}

		if (metricItem.key === 'pvlt6_age_range') {
			if (!performance.cutoff) {
				performance.cutoff = {};
			}

			performance.cutoff.ageRange = metricItem.value;
		}

		if (metricItem.key === 'pvlt6_impairment_cutoff') {
			if (!performance.cutoff) {
				performance.cutoff = {};
			}

			performance.cutoff.value = metricItem.value;
		}

		if (metricItem.key === 'pvlt6_impairment_classification') {
			if (metricItem.value === 'IMPAIRED') {
				performance.impaired = 1;
			} else {
				performance.impaired = 0;
			}
		}

		if (
			metricItem.key ===
			'pvlt6_impairment_threshold_adjusted_by_education'
		) {
			performance.score.adjusted = metricItem.value === 'true';
		}

		if (metricItem.key === 'pvlt6_percentile') {
			if (metricItem.value !== '-1') {
				const value = Number(metricItem.value);

				if (!isNaN(value)) {
					performance.percentile = value;
				}
			}
		}

		if (metricItem.key === 'rcr_no_score_first_12_seconds') {
			rcr = metricItem.value === 'true';
		}
	});

	if (rcr) {
		performance.rcr = true;
		performance.score.adjusted = false;
		performance.score.value = createDelayedRecallScore('--');
	}

	return { performance, trial };
}
