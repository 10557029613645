import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { getZendeskUrl, useCurrentUser } from 'api/user';
import { QueryKey } from 'api/query';
import { ERROR, INFO } from 'logging/linusLogger';

export function useZendeskRedirect() {
	const { data: currentUser } = useCurrentUser();
	const client = useQueryClient();

	useEffect(() => {
		async function zendeskSSOAuth(organizationId: string, userId: string) {
			const params = new URLSearchParams(window.location.search);
			const returnTo = params.get('return_to') ?? '';

			INFO('useZendeskRedirect >> Return to param:', returnTo);

			/**
			 * Only applies to zendesk users signing in via sso
			 */
			if (returnTo.match(/linushealth[\d]*.zendesk.com/gi)) {
				try {
					const zendeskRedirectUri = await client.fetchQuery({
						queryKey: [QueryKey.Zendesk, organizationId, userId],
						queryFn: () =>
							getZendeskUrl({ organizationId, userId, returnTo }),
					});
					// To be cleaned up in a follow-up PR
					INFO('Zendesk redirect uri', zendeskRedirectUri);
					window.location.replace(zendeskRedirectUri);
				} catch (err) {
					// Fail silently
					ERROR('error fetching zendesk sso url', err);
				}
			}
		}

		if (currentUser?.organizationId && currentUser?.id) {
			zendeskSSOAuth(currentUser.organizationId, currentUser.id);
		}
	}, [currentUser?.id, currentUser?.organizationId]);
}
