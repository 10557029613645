import { Organization, ValueListItem } from '@lh/core-backend-client';
import { useQueries } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';
import { orderValueLists } from 'utils/valueListUtils';

export async function getValueListItems(
	valueListId: string
): Promise<ValueListItem[]> {
	const service = await getOrganizationService();
	const { data } = await service.getValueListItemsByValueListId({
		valueListId,
	});
	return data;
}

export function useValueListItemsQuery(organization: Organization | null) {
	const lists = organization?.preferences.valueLists ?? [];

	return useQueries({
		queries: lists.map((list) => ({
			queryKey: [QueryKey.ValueList, list.id],
			queryFn: () => getValueListItems(list.id),
			select: (items: ValueListItem[]) => ({
				items: orderValueLists(items),
				type: list.type,
			}),
			staleTime: Infinity,
		})),
	});
}
