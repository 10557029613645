import {
	Patient,
	PatientApiCreatePatientRequest,
} from '@lh/eng-platform-subject-service-rest-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getPatientSubService } from 'api/getSubjectService';
import { QueryKey } from 'api/query';

export async function createPatient(
	patientInput: PatientApiCreatePatientRequest
) {
	if (!patientInput.organizationId) {
		throw new Error(
			"useCreatePatientMutation: organizationId can't be null"
		);
	}
	const service = await getPatientSubService();
	const { data } = await service.createPatient(patientInput);
	return data;
}

export function useCreatePatientMutation() {
	const client = useQueryClient();

	return useMutation({
		meta: {
			errorMessage: 'Error creating  patient',
		},
		mutationFn: createPatient,
		onSuccess: async (newPatient) => {
			client.setQueryData<Patient>(
				[QueryKey.Patient, newPatient.id, newPatient.organizationId],
				newPatient
			);
		},
	});
}
