import { Route, Routes } from 'react-router-dom';

import { RemoteAuth } from 'components/remoteAuth';
import { AccessDenied } from 'components/accessDenied/AccessDenied';
import { RegulatoryPage } from 'components/regulatory/RegulatoryPage';
import { SessionTimeout } from 'components/SessionTimeout/SessionTimeout';
import { UserNotFound } from 'components/userNotFound/UserNotFound';

import { LoggedInCheck } from './LoggedInCheck';
import { UserOnlyProvider } from '../UserProvider/UserOnlyProvider';
import { UserProvider } from '../UserContextProvider';
import { useAuthSetup } from '../../../hooks/useAuthSetup';
import { RootRoute } from './RootRoute';
import { useZendeskRedirect } from 'hooks';

export const RouteProvider = (): JSX.Element | null => {
	const { logged } = useAuthSetup();
	useZendeskRedirect();

	return (
		<Routes>
			<Route path={'/regulatory-label'} element={<RegulatoryPage />} />
			<Route path={'/access-denied'} element={<AccessDenied />} />
			<Route path={'/user-not-found'} element={<UserNotFound />} />
			<Route path={'/session-timeout'} element={<SessionTimeout />} />
			<Route path={'/remoteAuth'} element={<RemoteAuth />} />
			{/* If route is '/' redirect to the org specific landing page 
				which will then land here and hit the '/*' route */}
			{logged ? (
				<>
					<Route
						path='/'
						element={
							<UserOnlyProvider>
								<UserProvider>
									<RootRoute />
								</UserProvider>
							</UserOnlyProvider>
						}
					/>
					{/* Otherwise proceed to loginCheck  */}
					<Route
						path='/*'
						element={
							<UserOnlyProvider>
								<UserProvider>
									<LoggedInCheck />
								</UserProvider>
							</UserOnlyProvider>
						}
					/>
				</>
			) : null}
		</Routes>
	);
};
