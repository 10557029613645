import { AssignmentStatus } from '@lh/eng-platform-battery-service-rest-client';

import * as Sentry from '@sentry/browser';
import { useQueryClient } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getAssignments } from 'api/assignment';
import { useUpdatePatientMutation } from 'api/patient';
import { QueryKey } from 'api/query';
import { Status } from 'api/types';
import { UserContext } from 'components/context';
import { ButtonSm } from 'components/shared/designSystem';
import { ERROR } from 'logging/linusLogger';

import {
	Buttons,
	Container,
	Description,
} from './ArchivePatientConfirmation.style';

export type ArchivePatientConfirmationProps = {
	type: 'EditParticipant' | 'Kebab';
	patientId: string;
	firstName?: string;
	lastName?: string;
	organizationName: string;
	onCancel: () => void;
	onFail: () => void;
	onSuccess: () => void;
};

export const ArchivePatientConfirmation = ({
	type,
	firstName,
	lastName,
	organizationName,
	patientId,
	onCancel,
	onFail,
	onSuccess,
}: ArchivePatientConfirmationProps): JSX.Element | null => {
	const { currentUser } = useContext(UserContext);
	const client = useQueryClient();
	const { t } = useTranslation();

	const { mutateAsync: updatePatient } = useUpdatePatientMutation();

	const [showPrimaryButton, setShowPrimaryButton] = useState(true);

	async function hasArchivePermissions() {
		let nodes = null;

		if (type === 'EditParticipant') {
			const assignments = await client.fetchQuery({
				queryKey: [QueryKey.Assignments, patientId],
				queryFn: () => getAssignments({ participantId: patientId }),
				staleTime: Infinity,
			});
			nodes = assignments?.results;
		}

		if (!nodes) {
			return false;
		}

		if (
			nodes.length === 0 ||
			nodes[0]?.assignmentStatus !== AssignmentStatus.Started
		) {
			return true;
		} else {
			return false;
		}
	}

	async function handleArchive() {
		setShowPrimaryButton(false);

		const hasPermissions =
			type === 'EditParticipant' ? await hasArchivePermissions() : true;

		if (hasPermissions) {
			try {
				await updatePatient(
					{
						organizationId: currentUser.organizationId,
						subjectId: patientId,
						updatePatientInput: {
							status: Status.Archived,
						},
					},
					{
						onSuccess: () => {
							client.invalidateQueries({
								queryKey: [
									QueryKey.Patients,
									currentUser.organizationId,
								],
								exact: false,
							});

							onSuccess();
						},
						onError: () => {
							onFail();
						},
					}
				);
			} catch (err) {
				ERROR(
					`Error trying to archive patient, patientId: ${patientId}, organizationId: ${currentUser.organizationId}: `,
					err
				);
				Sentry.captureException(err);
				onFail();
			}
		} else {
			onFail();
		}
	}

	return (
		<Container>
			<Description>
				<Trans
					i18nKey='web.archivePatientModal.confirmation'
					values={{
						firstName,
						lastName,
						organizationName,
					}}
					components={{
						b: <strong />,
					}}
				/>
			</Description>
			<Buttons>
				<ButtonSm
					text={t`web.archivePatientModal.cancel`}
					width='160px'
					onClick={onCancel}
				/>
				<ButtonSm
					primary
					text={t`web.archivePatientModal.archive`}
					width='160px'
					disabled={!showPrimaryButton}
					onClick={handleArchive}
				/>
			</Buttons>
		</Container>
	);
};
