export enum Questionnaire {
	ADL = 'linus-adl',
	FAST = 'fast',
	GAD_7 = 'gad-7',
	GDS = 'gds',
	IADL = 'linus-iadl',
	ORIENTATION_6 = 'orientation-6',
	LHQ15 = 'lhq-15',
	LHQ32 = 'lhq-32',
	PHQ_8 = 'phq-8',
	NEUROGEN = 'neurogen',
}
