import { useQuery } from '@tanstack/react-query';

import { getOrganizationService } from 'api/getOrganizationService';
import { QueryKey } from 'api/query';

enum ZendeskArticleKeys {
	instructionsForUse = '4414840896539',
}

type FetchZendeskUrlInput = {
	article?: ZendeskArticleKeys;
	locale?: string;
	organizationId: string | null;
	returnTo?: string;
	userId: string | null;
};

export async function getZendeskUrl({
	article,
	locale,
	organizationId,
	returnTo,
	userId,
}: FetchZendeskUrlInput) {
	if (!organizationId) {
		throw new Error(
			"useGetZendeskRedirectUrl: organizationId can't be null"
		);
	}

	if (!userId) {
		throw new Error("useGetZendeskRedirectUrl: userId can't be null");
	}

	const service = await getOrganizationService();
	const { data } = await service.getUserZendeskSSOUrl({
		article,
		locale,
		organizationId,
		returnTo,
		userId,
	});
	return data;
}

export function useZendeskUrlQuery({
	article,
	locale,
	organizationId,
	userId,
}: FetchZendeskUrlInput) {
	return useQuery({
		enabled: !!organizationId && !!userId,
		meta: {
			errorMessage: `Error getting Zendesk URL for organizationId: ${organizationId} and userId: ${userId}`,
		},
		queryKey: [QueryKey.Zendesk, organizationId, userId],
		queryFn: () =>
			getZendeskUrl({ article, locale, organizationId, userId }),
	});
}
