import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getCorePatientService } from 'api/getSubjectService';
import { Patient, PatientApiUpdatePatientRequest } from 'api/types';
import { QueryKey } from 'api/query';

export async function updatePatient(
	searchInput: PatientApiUpdatePatientRequest
) {
	if (!searchInput.organizationId) {
		throw new Error(
			"useUpdatePatientMutation: organizationId can't be null"
		);
	}
	const service = await getCorePatientService();
	const { data } = await service.updatePatient(searchInput);
	return data;
}

export function useUpdatePatientMutation() {
	const client = useQueryClient();

	return useMutation({
		meta: {
			errorMessage: 'Error updating patient',
		},
		mutationFn: updatePatient,
		onSuccess: async (updatedPatient) => {
			client.setQueryData<Patient>(
				[
					QueryKey.Patient,
					updatedPatient.id,
					updatedPatient.organizationId,
				],
				updatedPatient
			);

			// TODO: Should be consumed when porting fully to REST
			// client.setQueryData<InfiniteData<PaginatedPatients>>(
			//     [QueryKey.Subjects, organizationId],
			//     (data) => {
			//         if (!data) {
			//             return;
			//         }

			//         return {
			//             ...data,
			//             pages: data.pages.map((page) => ({
			//                 ...page,
			//                 results: page.results.map((patient) => {
			//                     if (patient.id !== updatedPatient.id) {
			//                         return patient;
			//                     }

			//                     return { ...patient, ...updatedPatient };
			//                 })
			//             }))
			//         };
			//     }
			// );
		},
	});
}
