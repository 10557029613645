import { useContext, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';

import {
	OrganizationStatus,
	OrganizationType,
	PaginatedOrganization,
} from '@lh/core-backend-client';

import { useOrganizationStore } from '../../store/useOrganizationStore';
import { useUserOrganizationsQuery } from 'api/organization';
import { UserOnlyContext } from 'components/providers/UserProvider/UserOnlyContext';
import { UserContext } from './UserContext';
import { loginLandingPage } from 'components/auth/login/loginLandingPage';
import { useNavigate } from 'react-router-dom';

export type Organization = {
	id: string;
	name: string;
	isDefault?: boolean;
	status: OrganizationStatus;
};

export function sortOrganizationsByName(a: Organization, b: Organization) {
	if (a.name < b.name) {
		return 1;
	}
	if (a.name > b.name) {
		return -1;
	}

	return 0;
}

export function compareOrganizations(a: Organization, b: Organization) {
	if (a.isDefault) {
		return -1;
	}
	if (b.isDefault) {
		return 1;
	}
	if (a.status === OrganizationStatus.Live) {
		return -1;
	}
	if (b.status === OrganizationStatus.Live) {
		return 1;
	}
	return a.name.localeCompare(b.name);
}

export function sortOrganizations(orgs: Organization[]) {
	return orgs.sort(sortOrganizationsByName).sort(compareOrganizations);
}

export function useSwitchOrganization() {
	const { currentUser } = useContext(UserContext);
	const navigate = useNavigate();

	const { user } = useContext(UserOnlyContext);

	const currentOrganizationId = useOrganizationStore((state) => state.id);
	const setId = useOrganizationStore((state) => state.setId);
	const setName = useOrganizationStore((state) => state.setName);
	const setRole = useOrganizationStore((state) => state.setRole);
	const setStatus = useOrganizationStore((state) => state.setStatus);

	const { data: allUserOrganizations } = useUserOrganizationsQuery(
		user?.organizationId,
		user?.id
	);
	const primaryOrgRole = user?.roles.find((role) => role.primary);

	const [primaryOrganizationId, setPrimaryOrganizationId] = useState(
		primaryOrgRole?.organizationId
	);

	function switchOrganization(organization: Organization) {
		setId(organization.id);
		setName(organization.name);
		setStatus(organization.status);

		const role = user?.roles.find(
			(role) => role.organizationId === organization.id
		);
		if (role) {
			setRole(role);
		}

		const landingPage = loginLandingPage({
			...currentUser,
			roleId: role?.roleId || '',
		});
		navigate(landingPage);
	}

	const organizations = useMemo(() => {
		if (
			isEmpty<PaginatedOrganization[]>(allUserOrganizations) ||
			isEmpty(currentOrganizationId)
		) {
			return [];
		}

		return sortOrganizations(
			allUserOrganizations
				.filter(
					(organization) =>
						organization.type === OrganizationType.Clinical
				)
				.map((organization) => {
					const isDefaultOrganization =
						organization.id === primaryOrganizationId;

					return {
						id: organization.id,
						name: organization.name,
						isDefault: isDefaultOrganization,
						status: organization.status,
					} as Organization;
				})
		);
	}, [allUserOrganizations, primaryOrganizationId]);

	return {
		switchOrganization,
		setPrimaryOrganizationId,
		organizations,
	};
}
