import { PaginatedAssignments } from '@lh/eng-platform-battery-service-rest-client';
import { OperationToken } from '@lh/eng-platform-organization-service-rest-client';
import {
	Gender,
	Handedness,
	PaginatedPatients,
	Patient,
	SexAssignedAtBirth,
	AssignmentStatus,
} from '@lh/core-backend-client';

import { useQueryClient } from '@tanstack/react-query';
import { Formik, FormikHelpers } from 'formik';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { AnalyticsAction, sendEventData } from 'analytics';
import { useAssignmentsQuery } from 'api/assignment';
import { getOptionalFields } from 'api/organization';
import { useUpdatePatientMutation } from 'api/patient';
import { QueryKey } from 'api/query';
import { Input, PatientForm } from 'components/patients/PatientForm.types';
import { useInputs } from 'components/patients/PatientForm.utils';
import { getContactMethod } from 'components/patients/patient.helpers';
import { AlertError } from 'components/shared/Forms/Components/AlertError';
import { LoadingDots } from 'components/shared/LoadingDots';
import { ShowIfAuthorized } from 'components/shared/ShowIfAuthorized';
import { Icon } from 'components/shared/designSystem';
import { icons } from 'enums/icons';
import { MessageEnumItem, messageEnum } from 'enums/messageEnum';
import { FeatureFlagGate, FeatureFlags } from 'features/feature-flags';
import { hasOperation } from 'hasOperation';
import { ERROR } from 'logging/linusLogger';
import { CurrentUser } from 'types';
import { groupIntoN } from 'utils/arrays';
import { orgSpecificStringStrategy } from '../../stringStrategy/orgSpecificStringStrategy';
import { UserContext } from '../context/UserContext';
import { LinusInput } from '../shared/Forms/Components/LinusInput';
import {
	EditParticipantModel,
	editParticipantSchema,
	getModel,
} from '../shared/Forms/Schemas/editParticipantSchema';
import { InfoMessage } from '../shared/InfoMessage';
import { LinusModalDialog } from '../shared/LinusModalDialog';
import { ButtonLg } from '../shared/designSystem/ButtonLg';
import { ERROR_CODES, messages } from '../shared/errorMessages';

import {
	AlertContainer,
	Archive,
	ArchiveDescription,
	ArchiveTitle,
	ButtonRow,
	ButtonWrapper,
	Link,
	Loading,
	Row,
	Separator,
	StyledForm,
} from './EditParticipantInfoForm.style';

export type EditParticipantInfoFormProps = {
	participant: Patient;
	isTest?: boolean;
	mockedInputList?: Input[] | null;
	onCancel: () => void;
	onArchive: () => void;
	onEditParticipantSubmit?: () => void;
};

export const EditParticipantInfoForm = ({
	onArchive,
	participant,
	isTest = false,
	mockedInputList = null,
	onCancel,
	onEditParticipantSubmit,
}: EditParticipantInfoFormProps): JSX.Element | null => {
	const { currentUser } = useContext(UserContext);
	const client = useQueryClient();
	const theme = useTheme();
	const { t } = useTranslation();

	const {
		data: assignmentsData,
		isError: assignmentError,
		isFetched: loadedAssignments,
	} = useAssignmentsQuery({
		participantId: participant.id,
	});
	const { mutateAsync: updatePatient } = useUpdatePatientMutation();

	const initialInputs = useInputs(currentUser, PatientForm.Edit);

	const [canArchive, setCanArchive] = useState<null | boolean>(null);
	const [inputList, setInputList] = useState<Input[] | null>(
		mockedInputList ?? null
	);
	const [serverSideMessage, setServerSideMessage] =
		useState<MessageEnumItem>();
	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const participantType = orgSpecificStringStrategy(
		'Participant',
		currentUser
	);

	const hasArchivePermissions = useCallback(
		(
			assignmentsData: PaginatedAssignments | undefined,
			currentUser: CurrentUser
		) => {
			if (assignmentsData) {
				const hasArchiveOperation = hasOperation(currentUser, [
					OperationToken.EditParticipant,
				]);
				const nodes = assignmentsData?.results;
				const canArchive =
					nodes.length === 0 ||
					nodes[nodes.length - 1]?.assignmentStatus !==
						AssignmentStatus.Started;
				if (hasArchiveOperation && canArchive) {
					setCanArchive(true);
				} else {
					setCanArchive(false);
				}
			}
		},
		[assignmentsData, currentUser]
	);

	useEffect(() => {
		if (loadedAssignments && !assignmentError) {
			hasArchivePermissions(assignmentsData, currentUser);
		}
	}, [loadedAssignments, assignmentsData, assignmentError, currentUser]);

	useEffect(() => {
		async function createDynamicInputs() {
			const optionalFields = await client.fetchQuery({
				queryKey: [QueryKey.OptionalFields, currentUser.organizationId],
				queryFn: () => getOptionalFields(currentUser.organizationId),
				staleTime: Infinity,
			});

			if (optionalFields) {
				const validInputs = initialInputs.filter(
					(item) =>
						item.isRequired ||
						(item.fieldName &&
							optionalFields.includes(item.fieldName))
				);
				setInputList(validInputs);
			}
		}

		if (!isTest && !inputList && currentUser) {
			createDynamicInputs();
		}
	}, [inputList, currentUser]);

	function handleSuccessClose() {
		if (onEditParticipantSubmit) {
			return onEditParticipantSubmit();
		}

		return onCancel();
	}

	const onSubmit = async (
		values: EditParticipantModel,
		{ setSubmitting }: FormikHelpers<EditParticipantModel>
	) => {
		// WEB-2434
		const newBirthDate = new Date(values.birthDate);
		newBirthDate.setUTCHours(0, 0, 0, 0);

		const contactPhone = values.contactPhone
			? values.contactPhone.replace(/-/g, '')
			: null;

		try {
			const result = await updatePatient({
				organizationId: currentUser.organizationId,
				subjectId: values.id,
				updatePatientInput: {
					firstName: values.firstName,
					lastName: values.lastName,
					educationIds: [values.educationIds],
					gender: (values.gender as Gender) || null,
					handedness: values.handedness as Handedness,
					ethnicityIds: values.ethnicityIds
						? values.ethnicityIds.split(/, ?/)
						: [],
					raceIds: values.raceIds ? values.raceIds.split(/, ?/) : [],
					birthDate: newBirthDate.toISOString(),
					language: values.language,
					notes: values.notes,
					externalId: values.externalId ?? undefined,
					sexAssignedAtBirth:
						values.sexAssignedAtBirth as SexAssignedAtBirth,
					contactPreference: getContactMethod(
						values.contactEmail,
						values.contactPhone
					),
					contactEmail: values.contactEmail || null,
					contactPhone,
				},
			});
			client.setQueriesData(
				{ queryKey: [QueryKey.Patients] },
				(data?: PaginatedPatients) => {
					if (!data) {
						return;
					}
					const updatedPatientsResults = data.results.map(
						(patient) => {
							if (patient.id === result.id) {
								return result;
							}

							return patient;
						}
					);

					return { ...data, results: updatedPatientsResults };
				}
			);

			if (!result && !isTest) {
				setServerSideMessage(
					messageEnum.error(messages.mutationPayloadError)
				);
				setSubmitting(false);
				return;
			}

			sendEventData({
				eventType: AnalyticsAction.UpdatedUser,
			});
			setShowSuccessModal(true);
			setSubmitting(false);
		} catch (err) {
			ERROR(
				`Error trying to update patient from EditParticipantInfoForm, userId: ${values.id}, organizationId: ${currentUser.organizationId}: `,
				err
			);

			setSubmitting(false);

			//@ts-expect-error error is an AxiosError that typescript doesn't know about
			if (ERROR_CODES[err?.response?.data?.errorCode]) {
				setServerSideMessage(
					messageEnum.error(
						//@ts-expect-error error is an AxiosError that typescript doesn't know about
						ERROR_CODES[err?.response?.data?.errorCode]
					)
				);
				return;
			}

			setServerSideMessage(
				messageEnum.error(messages.mutationPayloadError)
			);
		}
	};

	if (!inputList) {
		return <LoadingDots />;
	}

	return (
		<Formik
			initialValues={getModel(
				currentUser.organizationParticipantLanguages,
				participant
			)}
			validationSchema={editParticipantSchema}
			onSubmit={onSubmit}
		>
			{({
				isSubmitting,
				values,
				touched,
				errors,
				submitCount,
				isValid,
			}) => {
				return showSuccessModal ? (
					<LinusModalDialog
						onClose={handleSuccessClose}
						title={t('web.participant.modal.title.updated')}
						acceptButtonCallback={handleSuccessClose}
					>
						<Trans
							i18nKey={'web.participant.shared.updateSuccess'}
							values={{
								participantType,
								firstName: values.firstName,
								lastName: values.lastName,
							}}
							components={[<strong />]}
						/>
					</LinusModalDialog>
				) : (
					<>
						{!isValid && submitCount !== 0 && (
							<AlertContainer>
								<AlertError
									text={t(
										'web.participant.forms.errors.missingInfo'
									)}
								/>
							</AlertContainer>
						)}
						<StyledForm>
							{groupIntoN(inputList, 2).map((row) => (
								<Row key={`${row[0].name}-row`}>
									{row.map((input) => {
										let showError = false,
											isTouched = false;
										if (errors[input.name]) {
											showError = true;
										}
										if (touched[input.name]) {
											isTouched = true;
										}
										return (
											<LinusInput
												err={showError}
												isTouched={isTouched}
												key={input.name}
												name={input.name}
												disabled={input.disabled}
												label={input.label}
												helperText={
													!showError
														? input.helperText
														: !isTouched
														? input.helperText
														: ''
												}
												clearable={input.clearable}
												type={input.type}
												dropdownOptions={
													input.dropdownOptions
												}
												dropUpSpace={input.dropUpSpace}
												preferDisplayLength={
													input.preferDisplayLength
												}
											/>
										);
									})}
								</Row>
							))}
							<InfoMessage
								messageEnum={serverSideMessage}
								showIf={!!serverSideMessage}
							/>
							<FeatureFlagGate flag={FeatureFlags.ArchivePatient}>
								{canArchive === null ? (
									<Loading>
										<LoadingDots />
									</Loading>
								) : (
									<ShowIfAuthorized
										operations={[
											OperationToken.ArchivePatients,
										]}
									>
										<Archive>
											<ArchiveTitle>
												<Icon
													icon={icons.RemoveUserSolid}
													color={
														canArchive
															? theme.color
																	.textAlert
															: theme.color
																	.textDisabled
													}
												/>
												<Link
													canArchive={canArchive}
													onClick={onArchive}
												>{t`web.archive`}</Link>
											</ArchiveTitle>
											{!canArchive && (
												<ArchiveDescription>{t`web.archivePatientModal.takingAssessment`}</ArchiveDescription>
											)}
										</Archive>
										<Separator />
									</ShowIfAuthorized>
								)}
							</FeatureFlagGate>
							<ButtonRow>
								<ButtonWrapper>
									<ButtonLg
										onClick={onCancel}
										text={t`web.participant.forms.cancelCTA`}
										width='200px'
									/>
								</ButtonWrapper>
								<ShowIfAuthorized
									operations={[
										OperationToken.EditParticipant,
									]}
									acceptEmpty={isTest}
								>
									<ButtonLg
										primary
										disabled={isSubmitting}
										text={t`web.participant.forms.saveCTA`}
										type='submit'
										width='200px'
									/>
								</ShowIfAuthorized>
							</ButtonRow>
						</StyledForm>
					</>
				);
			}}
		</Formik>
	);
};
