import {
	Configuration,
	DefaultApi,
	PatientApi,
} from '@lh/eng-platform-subject-service-rest-client';
import {
	Configuration as CoreConfiguration,
	PatientApi as CorePatientApi,
} from '@lh/core-backend-client';

import { config } from 'config';
import { Auth } from 'features/auth-service';

// This has patient-specific APIs exposed
// There's a world where we can roll this and other APIs from core-backend into a class/factory. One Day™.
// There's a similar world where we pass in basePath/token as config instead of making assumptions. One Day™.
export async function getCorePatientService() {
	try {
		const jwt = await Auth.currentIdToken();
		return new CorePatientApi(
			new CoreConfiguration({
				basePath: `${config.apiProtocol}://${config.apiHost}:${config.apiPort}`,
				baseOptions: {
					headers: {
						Authorization: `Bearer ${jwt.token}`,
					},
				},
			})
		);
	} catch (err) {
		throw new Error('Unauthorized');
	}
}

// Standard subject API, pre-migration client.
// TODO: Migrate this to use getCoreDefaultService() https://linushealth.atlassian.net/browse/CA-3856
export async function getSubjectService() {
	try {
		const jwt = await Auth.currentIdToken();
		return new DefaultApi(
			new Configuration({
				basePath: `${config.apiProtocol}://${config.apiHost}:${config.apiPort}`,
				baseOptions: {
					headers: {
						Authorization: `Bearer ${jwt.token}`,
					},
				},
			})
		);
	} catch (err) {
		throw new Error('Unauthorized');
	}
}

// Patient-specific API, pre-migration client
// TODO: Migrate this to use getCorePatientService() https://linushealth.atlassian.net/browse/CA-3856
export async function getPatientSubService() {
	try {
		const jwt = await Auth.currentIdToken();
		return new PatientApi(
			new Configuration({
				basePath: `${config.apiProtocol}://${config.apiHost}:${config.apiPort}`,
				baseOptions: {
					headers: {
						Authorization: `Bearer ${jwt.token}`,
					},
				},
			})
		);
	} catch (err) {
		throw new Error('Unauthorized');
	}
}
